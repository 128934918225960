/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, StateCta, LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "connect-your-phone-to-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#connect-your-phone-to-your-hearing-aids",
    "aria-label": "connect your phone to your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Connect your phone to your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Apple’s iPhones and iPads are renowned for their technological sophistication, their range of features, and their high-quality designs. This may be why, as of 2017, there were around 85 million iPhone users in the USA. As around 15 percent of Americans also report some degree of hearing impairment (a total of about 38 million people), it’s a fair bet that there are millions of people who use iPhones or iPads and also struggle to hear. But what if the two could be brought together, helping those with auditory issues improve their hearing? Actually, that’s definitely a possibility, with plenty of iPhone or iPad compatible hearing devices on the market. Let’s find out more about them."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-technology-behind-made-for-iphone-and-ipad-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-technology-behind-made-for-iphone-and-ipad-hearing-aids",
    "aria-label": "the technology behind made for iphone and ipad hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The technology behind made for iPhone and iPad hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Made for iPhone or iPad hearing aids are specifically designed to provide optimal sound quality when used alongside Apple’s phones and tablets. When these devices are “paired” with ", React.createElement(_components.a, {
    href: "/hearing-aids/brands",
    className: "c-md-a"
  }, "certain brands of hearing aids"), ", they can send audio feeds directly to the hearing aid’s speakers, delivering much clearer conversations, and making it easier to use the telephone. But that’s not all."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing devices made for Apple’s products can also be controlled using your iPhone or iPad. There are iOS apps which let you track your hearing aid’s ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "battery life"), " or change the volume and balance settings. And they also let you access a service called Live Listen, which is designed specifically for the hearing impaired. With this product installed, your phone or tablet can then become an additional microphone, which can have many useful applications. For instance, if you are in a ", React.createElement(_components.a, {
    href: "/hearing-loss/everyday-life",
    className: "c-md-a"
  }, "crowded restaurant"), " and are trying to maintain a chat with a friend, you can place your phone near them, amplifying their voice and helping to screen out background noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, as you can see, there are plenty of reasons to invest in hearing aids that are designed to work with your Apple devices. The question is, which brands provide such products, and what are their unique features? Here’s a quick run down of the major options that are available."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "brands-offering-made-for-iphone-and-ipad-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#brands-offering-made-for-iphone-and-ipad-hearing-aids",
    "aria-label": "brands offering made for iphone and ipad hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Brands offering made for iPhone and iPad hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/",
    className: "c-md-a"
  }, "ReSound"), " – Specializing in smart hearing aids, ReSound is a market leader in Apple-compatible auditory devices, and have the largest selection for iPhone users. Models like the Linx 3D are cutting edge options with situational awareness features, high-quality background noise screening and – crucially – the Smart 3D app, which runs on iOS. Other ReSound hearing aids to consider include their Sola, Cala, and ENZO ranges, all of which work with the iOS app."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Audibel – Audibel’s A3i (where the “i” stands for iPhone) is another popular made for iPhone option. In their case, the Hearing Control App provides a range of options to customize your hearing aid, and it also works with the Apple Watch, iPad, and iPod Touch, so it’s a great option for those with a large Apple collection."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Beltone – Beltone has joined the iPhone compatibility trend, launching their own HearPlus app for iOS devices. Working with Beltone Plus, First, and Legend hearing aids, it provides the ability to check battery levels, change sound settings, and create custom settings for your hearing device (and also works with Samsung Galaxy smartphones)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Cochlear – Cochlear’s Baha range is another leading made-for-iPhone hearing product. Relying on the Nucleus Smart app, their devices are optimized to stream audio and video footage from iPhones, as well as FaceTime calls, and they come with the standard range of phone-based hearing aid controls."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Danavox – Spanish company Danavox have come up with their Aio, Gaia, and Logar hearing aids – all of which are completely iPhone and iPad ready."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/",
    className: "c-md-a"
  }, "Widex"), " – The Widex Beyond is another state of the art made for iPhone hearing aid, claiming to offer the finest sound quality of all when paired with Apple’s smartphones. Optimized for streaming movies and TV, it also hooks up to telecoils and other smart devices, providing a huge range of hearing enhancements."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "MicroTech – MicroTech’s Kinect hearing aids are another solid option. They use an app called TruLink to deliver plenty of hearing aid controls, and also work with Apple Watches and iPods."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "NuEar – NuEar’s iSDS hearing aids are also made specifically for use with Apple iPhones, and like MicroTech’s devices, they use TruLink to deliver plenty of extra features."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "EarLens – EarLens is a little bit different to most hearing aids, using light to process sounds, which helps to keep background noise and “whistling” to a minimum. As an added bonus, their devices are totally iPhone compatible, as well."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-should-you-try-iphone-or-ipad-compatible-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-should-you-try-iphone-or-ipad-compatible-hearing-aids",
    "aria-label": "why should you try iphone or ipad compatible hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why should you try iPhone or iPad compatible hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are plenty of great things about combining your Apple communications devices with high-quality hearing aids. Between them, the two pieces of technology can result in better hearing and a higher quality of life. With your iPhone or iPad acting as a microphone, you will be able to set up environments that are much better suited to easy conversation, or hear things like presentations more easily."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s also worth noting that there’s a difference between Apple phones and Android phones when we’re talking about hearing. While you can stream audio from Android phones to hearing aids, you will usually need a ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth device"), " to act as an intermediary. With iPhones, you just stream directly from one device to another – a much more convenient solution. Not everyone will need to fuse phones and hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, it’s an option that hearing-impaired people can use, and should consider if they are Apple users. Get in touch, and we will help you find the right hearing aid to make the most of your iPad or iPhone."), "\n", React.createElement(LandingPageCta, {
    copy: "Try iPhone hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
